import axios from 'axios';

const {REACT_APP_API_ENDPOINT} = process.env;

export function listEvents() {
    return axios.get(REACT_APP_API_ENDPOINT + 'cal', {withCredentials: true}).then((res) => {
        return res.data
    })
        .catch(function (error) {
            console.log(error);
        })
}


export function createEvent(obj, callbackOk, callbackKo) {

    return axios.post(REACT_APP_API_ENDPOINT + 'cal/create', obj, {withCredentials: true}).then((res) => {
        callbackOk(res.data)
    })
        .catch(function (error) {
            callbackKo(error)
        })
}

export function initCreate(obj) {
    return axios.post(REACT_APP_API_ENDPOINT + 'cal/initCreate', obj, {withCredentials: true}).then((res) => {
        return res.data
    })
        .catch(function (error) {
            console.log(error);
        })
}
