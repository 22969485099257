import * as React from 'react';
import './App.css';
import zzp from './zzp.jpg';
import {Button, Card, CardActions, CardContent, Divider, Grid, Link, Paper, Typography} from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';

function ContactTab({goToRdvTab}) {

    return (
        <>
            <Grid container columnSpacing={10} rowSpacing={2} align="center">
                <Grid item xs={0} sm={4} sx={{display: {xs: 'none', sm: 'block'}}}>
                    <img src={zzp} width="50%" alt="Marine Florins"/>

                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper elevation={3}>
                        <Grid container>
                            <Grid item xs={12} lg={4}>
                                <Card>
                                    <CardContent align="left">
                                        <Typography variant="h6" align="center">
                                            Marine Florins
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body2" color="text.secondary"
                                                    style={{lineHeight: "40px", fontSize: '0.8rem'}}>
                                            Marine vous accueille les lundis, mercredis et jeudis.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary"
                                                    style={{lineHeight: "40px", fontSize: '1rem'}}>
                                            <CallTwoToneIcon/> <Link href="tel:0483017805">0483/01.78.05</Link>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary"
                                                    style={{lineHeight: "40px", fontSize: '0.9rem'}}>
                                            <LocationOnIcon/> Rue de la déportation, 110, Tubize
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary"
                                                    style={{lineHeight: "40px", fontSize: '1rem'}}>
                                            <MailOutlineIcon/> <Link
                                            href="mailto:marineflorins@outlook.com">marineflorins@outlook.com</Link>
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="caption" color="text.primary" align="left">
                                            N° Inami: 6-09108-52-801
                                        </Typography>
                                        <Divider/>

                                    </CardContent>
                                    <CardActions>
                                        <LanguageIcon/> <Button onClick={goToRdvTab} size="small">Prendre rendez-vous en
                                        ligne</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <iframe title="Google Maps"
                                        height="100%import { Component, OnInit } from '@angular/core';" width="100%"
                                        frameBorder="0" scrolling="no" marginHeight="0"
                                        marginWidth="0" id="gmap_canvas"
                                        src="https://maps.google.com/maps?width=401&amp;height=357&amp;hl=en&amp;q=Rue%20de%20la%20d%C3%A9portation,%20110%20Tubize+(Logop%C3%A9die%20Tubize)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                <script type='text/javascript'
                                        src='https://embedmaps.com/google-maps-authorization/script.js?id=29ddbee34b879262837a2faaf191f02b8a805e51'></script>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

        </>
    );
}

export default ContactTab;
