import * as React from 'react';

import './App.css';
import {Alert, Backdrop, Box, Button, Container, Step, StepLabel, Stepper, Typography} from '@mui/material';
import RdvStepCoord from './RdvCoord.js';
import RdvStepHoraire from './RdvHoraire.js';
import RdvStepConfirm from './RdvConfirm.js';
import RdvStepEnd from './RdvEnd.js';
import CallTwoToneIcon from "@mui/icons-material/CallTwoTone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {createEvent, initCreate} from './backend.js'

function RdvTab({activeStep, setActiveStep, goToContactTab}) {

    const [openAlert, setOpenAlert] = React.useState(false);
    const [openAlertText, setOpenAlertText] = React.useState('');

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const steps = ['Coordonnées du patient', 'Date et heure du rendez-vous', 'Confirmation'];

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            birthdate: '',
            phone: '',
            mail: '',
            date: '',
            time: '',
            comment: '',
            otp: ''

        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .max(25, 'Maximum 25 caractères')
                .required('Champ requis')
                .matches(/^[a-zA-ZàáâäæçéèêëîïôœùûüÿÀÂÄÆÇÉÈÊËÎÏÔŒÙÛÜŸ -']+$/, 'Prénom non valide'),
            lastName: Yup.string()
                .max(35, 'Maximum 35 caractères')
                .required('Champ requis')
                .matches(/^[a-zA-ZàáâäæçéèêëîïôœùûüÿÀÂÄÆÇÉÈÊËÎÏÔŒÙÛÜŸ -']+$/, 'Nom non valide'),
            mail: Yup.string().email('Veuillez entrer une adresse email valide')

                .required('Champ requis'),
            phone: Yup.string()
                .required('Champ requis')
                .matches(/^(\+?32( )?|00( )?32( )?|0)4[ |\/|-]?(\d[ |\/|-]?){8}$/,
                    "Veuillez entrer un numéro de gsm belge valide"),
            birthdate: Yup.date()
                .typeError('Veuillez entrer une date de naissance valide')
                .required('Champ requis')
                .min(new Date(1900, 1, 1), "Date trop ancienne")
                .max(new Date(), "La date ne peut pas être postérieure à aujourd'hui"),
            date: Yup.string().required(),
            time: Yup.string().required(),
            otp: Yup.string().required()

        }),
        onSubmit: values => submitValues(values),
    });

    const submitValues = (values) => {
        createEvent(values, ok => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }, err => {
            var errMsg = "Erreur lors de l'envoi du formulaire";
            if (err.response && err.response.data) {
                errMsg += ": " + err.response.data
            }

            setOpenAlertText(errMsg)
            setOpenAlert(true)
        })

    }
    const handleNext = () => {

        if (activeStep === 0) {
            if (!formik.values.firstName ||
                formik.errors.firstName || formik.errors.lastName || formik.errors.mail ||
                formik.errors.phone || formik.errors.birthdate) {
                formik.setFieldTouched('firstName', true);
                formik.setFieldTouched('lastName', true);
                formik.setFieldTouched('mail', true);
                formik.setFieldTouched('phone', true);
                formik.setFieldTouched('birthdate', true);

                setOpenAlertText("Formulaire incomplet")
                setOpenAlert(true)
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            if (!formik.values.date || !formik.values.time) {
                setOpenAlertText("Veuillez choisir une heure de rendez-vous")
                setOpenAlert(true)
            } else {
                initCreate({phone: formik.values.phone})
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
        } else if (activeStep === 2) {
            if (!formik.values.otp || formik.errors.otp) {
                setOpenAlertText("Veuillez compléter le code reçu par SMS")
                setOpenAlert(true)
            } else {
                formik.handleSubmit()
            }
        }


    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        formik.resetForm()
        setActiveStep(0)
    };

    return (

        <div>
            <Container>
                <Typography variant="h5" color="text.secondary" align="left">
                    Prendre rendez-vous en ligne
                </Typography>
                <Typography component={'div'}  variant="body2" color="text.secondary" align="left"
                            style={{fontSize: '0.9rem', marginBottom: '20px'}}>
                    Vous pouvez également prendre rendez-vous par
                    <Button variant="text" onClick={goToContactTab}>e-mail</Button>
                    <MailOutlineIcon onClick={goToContactTab} color="primary"
                                     sx={{display: {xs: 'none', sm: 'inline'}}}/>
                    &nbsp; ou par
                    <Button variant="text" onClick={goToContactTab}>téléphone</Button>
                    <CallTwoToneIcon onClick={goToContactTab} color="primary"
                                     sx={{display: {xs: 'none', sm: 'inline'}}}/>
                    .

                </Typography>
                <Backdrop
                    sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                    open={openAlert}
                    onClick={handleCloseAlert}
                >
                    <Alert severity="warning">{openAlertText}</Alert>

                </Backdrop>

                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelPropsSm = {sx: {display: {xs: 'none', sm: 'block'}}};
                        const labelPropsXs = {sx: {display: {xs: 'block', sm: 'none'}}};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelPropsSm}>{label}</StepLabel>
                                <StepLabel {...labelPropsXs}></StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <RdvStepEnd formik={formik}/>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            <Box sx={{flex: '1 1 auto'}}/>
                            <Button onClick={handleReset}>Recommencer</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                            >
                                Précédent
                            </Button>
                            <Box sx={{flex: '1 1 auto'}}/>

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Confirmer le rendez-vous' : 'Suivant'}
                            </Button>
                        </Box>
                        <Typography component={'div'}  sx={{mt: 2, mb: 1}}>
                            {activeStep === 0 ? <RdvStepCoord formik={formik}/> : ''}
                            {activeStep === 1 ? <RdvStepHoraire formik={formik}/> : ''}
                            {activeStep === 2 ? <RdvStepConfirm formik={formik}/> : ''}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                            >
                                Précédent
                            </Button>
                            <Box sx={{flex: '1 1 auto'}}/>

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Confirmer le rendez-vous' : 'Suivant'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
}

export default RdvTab;
