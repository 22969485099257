import * as React from 'react';

import './App.css';
import {AddToCalendarButton} from 'add-to-calendar-button-react';
import Grid from '@mui/material/Unstable_Grid2';
import {Card, CardContent, Stack, Typography} from '@mui/material';
import FmdBadIcon from '@mui/icons-material/FmdBad';

function RdvStepEnd({formik}) {

    const formatDate = (date) => {
        if (date && date.length === 8) {
            return date.substring(6, 8) + '-' + date.substring(4, 6) + '-' + date.substring(0, 4)
        }
        return date
    }
    const formatDateCal = (date) => {
        if (date && date.length === 8) {
            return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)
        }
        return date
    }

    const formatEndTime = (time) => {

        if (!time) return time;

        let [heures, minutes] = time.split(":").map(Number);
        let date = new Date();
        date.setHours(heures);
        date.setMinutes(minutes);

        // Ajouter 30 minutes
        date.setMinutes(date.getMinutes() + 30);

        // Reformater l'heure en 'hh:mm'
        let heuresAjoutees = date.getHours().toString().padStart(2, '0');
        let minutesAjoutees = date.getMinutes().toString().padStart(2, '0');

        return `${heuresAjoutees}:${minutesAjoutees}`;
    }

    const getCalDetails = () => {
        return `<b>Rendez-vous Logopédie</b>
            <p>
                Vous avez rendez-vous pour ${formik.values.firstName} ${formik.values.lastName} avec notre logopède Marine Florins.<BR/>
            </p>
            <p>
                <b> L'adresse: Rue de la déportation, 110, Tubize</b><BR/>
                En cas d'empêchement, merci de me contacter le plus rapidement possible au 0483/01.78.05 ou par mail: 
                <a href="mailto:marineflorins@outlook.com">marineflorins@gmail.com</a>
            </p>
        `
    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Typography variant="h6" align="center">
                        <br/>
                        Votre rendez-vous avec Marine Florins est confirmé!<br/>
                    </Typography>
                </Grid>
                <Grid xs={0} sm={4}>
                </Grid>
                <Grid xs={12} sm={4}>
                    <Typography  component={'div'} variant="body2" color="text.primary" align="left">
                        <Card variant="outlined">
                            <CardContent>
                                <Stack direction="row" spacing={3}>
                                    <Typography variant="body">
                                        <b>{"Jour"}</b> {formatDate(formik.values.date)}
                                    </Typography>
                                    <Typography variant="body">
                                        <b>{"Heure"}</b> {formik.values.time}
                                    </Typography>
                                </Stack>
                            </CardContent>
                            <CardContent>
                                <b>Adresse:</b> Rue de la déportation, 110, Tubize
                            </CardContent>
                        </Card>

                    </Typography>
                </Grid>
                <Grid xs={12} sm={4}>
                    <AddToCalendarButton
                        name="Rendez-vous logopède"
                        startDate={formatDateCal(formik.values.date)}
                        endDate={formatDateCal(formik.values.date)}
                        startTime={formik.values.time}
                        label="Ajouter à mon calendrier"
                        endTime={formatEndTime(formik.values.time)}
                        options={['Apple', 'Google', 'Yahoo', 'iCal', 'Outlook.com']}
                        timeZone="Europe/Brussels"
                        location="Rue de la déportation, 110, Tubize"
                        description={getCalDetails()}
                    ></AddToCalendarButton>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="body2" color="text.primary" align="center">
                        <FmdBadIcon color="primary"/> En cas d'empêchement, veuillez nous le communiquer le plus tôt
                        possible, au plus tard la veille du rendez-vous.
                    </Typography>
                </Grid>
            </Grid>


        </>
    );
}

export default RdvStepEnd;
