import * as React from 'react';
import zzp from './zzp.jpg';
import './App.css';
import {Box, Button, Card, CardActions, CardContent, Grid, Paper, Typography} from '@mui/material';

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import Diversity2Icon from '@mui/icons-material/Diversity2';

function AccueilTab({goToRdvTab}) {

    return (

        <>
            <Box
                sx={{
                    height: '60vh',
                    overflowY: 'auto',
                    padding: 1,
                }}
            >
                <Grid container columnSpacing={10} rowSpacing={2} align="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Bienvenue sur le site de Marine Florins, votre logopède de confiance, à Tubize
                        </Typography>
                    </Grid>

                    <Grid item xs={0} sm={4} sx={{display: {xs: 'none', sm: 'block'}}}>
                        <img src={zzp} width="50%" alt="Marine Florins"/><br/>
                        <Typography variant="caption" align="left">
                            N° Inami: 6-09108-52-801
                        </Typography>

                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Paper elevation={3}>
                            <Card>
                                <CardContent>
                                    <AccountCircleTwoToneIcon fontSize="large"/>

                                    <Typography variant="body1" color="text.primary" align="left">
                                        Logopède conventionnée, je vous accueille dans un cadre chaleureux et
                                        bienveillant,
                                        dédié à l’accompagnement et au développement des compétences de communication
                                        et des troubles des apprentissages.
                                        Que ce soit pour les enfants ou les adolescents,
                                        je propose un suivi personnalisé adapté à vos besoins spécifiques.


                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small"
                                            onClick={goToRdvTab}>Prendre rendez-vous</Button>
                                </CardActions>
                            </Card>
                        </Paper>

                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="h6">
                            La logopédie
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <RecordVoiceOverIcon fontSize="large"/>

                                <Typography variant="body2" color="text.secondary" align="center">
                                    <b>Pourquoi consulter une logopède ?</b><br/><br/>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" align="left">
                                    Les troubles logopédiques peuvent prendre de nombreuses formes :
                                    difficultés de langage, troubles de la parole,
                                    difficultés scolaires, et bien d'autres.
                                    Un dépistage rapide permet une prise en charge qualitative.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={goToRdvTab}>Prendre rendez-vous</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <ChatIcon fontSize="large"/><br/>
                                <Typography variant="body" color="text.secondary">
                                    <b>Les services proposés :</b> <br/>
                                </Typography>
                                <Typography component={'div'} variant="body1" color="text.secondary" align="left">
                                    <ul>
                                        <li> Dépistage et évaluation des troubles du langage, des apprentissages et de
                                            la communication.
                                        </li>
                                        <li> Rééducation des troubles de l’articulation et de la phonation.</li>
                                        <li> Prise en charge des enfants et adolescents présentant des troubles des
                                            apprentissages.
                                        </li>
                                    </ul>
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={goToRdvTab}>Prendre rendez-vous</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Diversity2Icon fontSize="large"/><br/>
                                <Typography variant="body" color="text.secondary" align="center">
                                    <b>Une approche humaine et bienveillante</b> <br/> <br/>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" align="left">
                                    Chaque séance est une opportunité de grandir et de s’épanouir.
                                    Je mets un point d'honneur à créer une relation de confiance avec mes patients,
                                    en adoptant une approche individualisée et respectueuse du rythme de chacun.
                                    <br/> <br/>
                                    En tant que logopède conventionnée, je vous assure un accompagnement de qualité
                                    avec des tarifs encadrés, garantissant un accès équitable aux soins pour tous.
                                    <br/> <br/>
                                    Que vous soyez ici pour obtenir des informations, prendre un premier rendez-vous
                                    ou simplement en savoir plus sur les services offerts,
                                    je suis à votre disposition pour répondre à toutes vos questions.
                                    <br/> <br/>
                                    Contactez moi dès aujourd'hui pour un premier échange ou pour planifier une
                                    consultation.


                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={goToRdvTab}>Prendre rendez-vous</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

            </Box>


        </>
    );
}

export default AccueilTab;
