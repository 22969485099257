import * as React from 'react';
import './App.css';
import AccueilTab from './Accueil.js';
import RdvTab from './Rdv.js';
import ContactTab from './Contact.js';
import {theme, darkTheme} from './theme.js';

import {
    Box,
    IconButton,
    Link,
    Popover,
    Stack,
    Switch,
    Tab,
    Typography,
    Container,
    CssBaseline
} from '@mui/material';

import {
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';
import {ThemeProvider} from '@mui/material/styles';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

//import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import useMediaQuery from '@mui/material/useMediaQuery';



function App() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorText, setAnchorText] = React.useState(null);

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [currentTheme, setCurrentTheme] = React.useState(prefersDarkMode ? darkTheme : theme);

    const openEl = Boolean(anchorEl);

    const [selectedTab, setSelectedTab] = React.useState('1');
    const [activeStep, setActiveStep] = React.useState(0);


    React.useEffect(() => {
        setCurrentTheme(prefersDarkMode ? darkTheme : theme);
    }, [prefersDarkMode]);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const onContactIconClick = (event, text) => {
        setAnchorText(text)
        setAnchorEl(event.target)

    }

    const onSwitchTheme = (event) => {
        setCurrentTheme(event.target.checked ? darkTheme : theme);
    }

    const goToRdvTab = () => {
        setSelectedTab('2')
    };
    const goToContactTab = () => {
        setSelectedTab('3')
    };


    return (
        <div className="App">
            <ThemeProvider theme={currentTheme}>
                <CssBaseline/>
                <header>
                    <Container sx={{backgroundColor: 'background.paper'}}>
                        <Box
                            sx={{
                                backgroundImage: "url('titre.png')",
                                backgroundRepeat: "no-repeat",
                                height: "100px",
                                width: "100%",
                            }}
                        >
                            <Typography variant="h1" gutterBottom color="customColors.title">
                                Logopédie Marine Tubize
                            </Typography>

                        </Box>
                    </Container>
                </header>

                <TabContext value={selectedTab}>
                    <Box display="flex" justifyContent="center" alignItems="center"
                         sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example" centered>
                            <Tab label="Accueil" value="1"/>
                            <Tab label="Rendez-vous"
                                 value="2"
                                 onClick={() => {
                                     setActiveStep(0)
                                 }}/>
                            <Tab label="Contact" value="3"/>
                        </TabList>
                        <Stack direction="row" spacing={2} sx={{position: 'absolute', right: "20px", top: "130px"}}>
                            <IconButton aria-label="Téléphone"
                                onClick={(ev) => onContactIconClick(ev, <React.Fragment>Tél: <Link
                                     href='tel:0483017805'>0483/01.78.05</Link></React.Fragment>)}>
                                <CallTwoToneIcon />
                            </IconButton>
                            <IconButton aria-label="Email"
                                onClick={(ev) => onContactIconClick(ev, <React.Fragment>Email: <Link
                                    href="mailto:marineflorins@outlook.com">marineflorins@outlook.com</Link></React.Fragment>)}>
                                <MailOutlineIcon />
                            </IconButton>
                            <Stack direction="row">
                                <LightModeOutlinedIcon fontSize="small" sx={{position: 'relative', top: "10px"}} />
                                <Switch onChange={event => onSwitchTheme(event)}
                                        color="primary" checked={(currentTheme===darkTheme)}
                                />
                                <DarkModeOutlinedIcon fontSize="small" sx={{position: 'relative', top: "10px"}}/>
                            </Stack>
                        </Stack>

                        <Popover
                            open={openEl}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{p: 2}}>
                                {anchorText}
                            </Typography>
                        </Popover>


                    </Box>
                    <TabPanel value="1">
                        <AccueilTab goToRdvTab={goToRdvTab}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <RdvTab activeStep={activeStep} setActiveStep={setActiveStep} goToContactTab={goToContactTab}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <ContactTab goToRdvTab={goToRdvTab}/>
                    </TabPanel>
                </TabContext>
            </ThemeProvider>


        </div>
    );
}

export default App;
