import * as React from 'react';

import './App.css';
import {Box, FormControl, InputAdornment, InputLabel, TextField} from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Unstable_Grid2';


function RdvStepCoord({formik}) {

    return (
        <Box sx={{p: 3, border: '1px dashed grey'}}>
            <Grid container spacing={2} align="left">
                <Grid xs={6} sm={6}>
                    <TextField fullWidth required
                               id="firstName" name="firstName"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.firstName}
                               label="Prénom"
                               helperText={
                                   formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null
                               }
                               error={formik.touched.firstName && formik.errors.firstName}
                               variant="outlined"/>


                </Grid>
                <Grid xs={6} sm={6}>
                    <TextField fullWidth required
                               id="lastName" name="lastName"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.lastName}
                               label="Nom"
                               helperText={
                                   formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null
                               }
                               error={formik.touched.lastName && formik.errors.lastName}
                               variant="outlined"/>
                </Grid>

                <Grid xs={12} sm={6}>

                    <TextField fullWidth required
                               id="phone" name="phone"
                               label="Numéro de GSM"
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <PhoneIcon/>
                                       </InputAdornment>
                                   ),
                               }}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.phone}
                               helperText={
                                   formik.touched.phone && formik.errors.phone ? formik.errors.phone : null
                               }
                               error={formik.touched.phone && formik.errors.phone}
                    />

                </Grid>

                <Grid xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel required shrink id="birthdate-label" htmlFor="birthdate">Date de
                            naissance</InputLabel>
                        <TextField required
                                   id="birthdate" name="birthdate"
                                   type="date"
                                   inputProps={{min: "1900-01-01", max: "2024-12-31"}}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   helperText={
                                       formik.touched.birthdate && formik.errors.birthdate ? formik.errors.birthdate : null
                                   }
                                   error={formik.touched.birthdate && formik.errors.birthdate}
                                   value={formik.values.birthdate}
                        />
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6}>

                    <TextField fullWidth required
                               id="mail" name="mail"
                               label="Email"
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <EmailIcon/>
                                       </InputAdornment>
                                   ),
                               }}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.mail}
                               helperText={
                                   formik.touched.mail && formik.errors.mail ? formik.errors.mail : null
                               }
                               error={formik.touched.mail && formik.errors.mail}
                    />

                </Grid>
                <Grid xs={12} sm={6}>

                    <TextField fullWidth
                               id="comment" name="comment"
                               label="Remarques, raison de votre visite, ..."
                               minRows={3}
                               multiline
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.comment}
                    />


                </Grid>

            </Grid>
        </Box>
    );
}

export default RdvStepCoord;
