import * as React from 'react';

import './App.css';
import {Button, Grid, Tooltip,} from '@mui/material'
import {MuiOtpInput} from 'mui-one-time-password-input'

function RdvStepConfirm({formik}) {
    const [otp, setOtp] = React.useState('');

    const formatDate = (date) => {
        if (date && date.length === 8) {
            return date.substring(6, 8) + '-' + date.substring(4, 6) + '-' + date.substring(0, 4)
        }
        return date
    }

    const handleOtpChange = (newValue) => {
        setOtp(newValue)
    }


    const setFinalOtp = async (value) => {
        setOtp(value)
        await formik.setFieldValue('otp', value)
    }

    const asyncSubmit = async () => {
        formik.handleSubmit()
    };

    return (
        <>

            <Grid container spacing={1} align="left">
                <Grid xs={0} sm={3}>
                </Grid>
                <Grid xs={12} sm={6} align="center">
                    Pour confirmer le
                    <Tooltip
                        title={
                            <React.Fragment>
                                {"Rendez-vous au nom de  "} {formik.values.firstName} {" "} {formik.values.lastName}
                                <br/>
                                <b>{"Jour"}</b> {formatDate(formik.values.date)}
                                <br/>
                                <b>{"Heure"}</b> {} {formik.values.time}

                            </React.Fragment>
                        } placement="top">
                        <Button>rendez-vous</Button>
                    </Tooltip>

                    , entrez le code reçu
                    <Tooltip
                        title={
                            <React.Fragment>
                                {"Un code de 6 chiffes vous a été envoyé au numéro "} {formik.values.phone}
                                <br/><b>{"Pas reçu? "}</b>{"Vérifier le numéro utilisé, et réessayez"}

                            </React.Fragment>
                        } placement="top">
                        <Button>par SMS</Button>
                    </Tooltip>

                    <MuiOtpInput autoFocus TextFieldsProps={{placeholder: '-'}} value={otp} onChange={handleOtpChange}
                                 onComplete={setFinalOtp} length={6}/>

                </Grid>
                <Grid xs={0} sm={3}>
                </Grid>


                <Grid xs={12} align="center">
                    <Button onClick={asyncSubmit}>
                        Confirmer
                    </Button>
                </Grid>
            </Grid>

        </>
    );
}

export default RdvStepConfirm;
