import {red} from '@mui/material/colors';
import {deepmerge} from "@mui/utils";
import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: red[500],
        },
        secondary: {
            main: red[800],
        },
        customColors: {
            title: "#FFFFFF",
        },
    },
    typography: {
        h1: {

            fontSize: '2.0rem',
            '@media (min-width:600px)': {
                fontSize: '2.5rem',
            },
            [createTheme().breakpoints.up('md')]: {
                fontSize: '4rem',
            },
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: red[500],
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                        color: red[800], // Couleur des liens au survol
                    },
                },
            },
        },
    }
});

export const darkTheme = createTheme(
    deepmerge(theme, {
        palette: {
            mode: 'dark',

            background: {
                default: '#121212', // Custom background color
                paper: '#1d1d1d', // Custom paper background color
            },
            text: {
                primary: '#fff2e6', // Default text color (e.g., white)
                secondary: '#ffe6cc', // Secondary text color
            },
            action: {
                active: '#ffd9b3',  // Default color for icons
            },
        },
        typography: {
            h6: {
                color: '#ffd9b3',
            },
        },

        components: {
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: red[500], // Customize the color of the selected tab's indicator (underline)
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: '#fff', // Default color of the tab labels
                        '&.Mui-selected': {
                            color: red[500], // Color of the selected tab
                        },
                        '&:hover': {
                            color: red[300], // Color of the tab label on hover
                        },
                    },
                },
            },
        },

    })
);


